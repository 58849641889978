import * as React from "react";
import Article from "components/pages/front/Article";
import * as Router from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import { getArticle } from "graphql/queries";
import { UpdateArticleInput as ArticleType } from "API";

export default function PreviewArticle() {
  const { id } = Router.useParams();
  const [article, setArticle] = React.useState<ArticleType>();
  const [relatedArticles, setRelatedArticles] = React.useState<ArticleType[]>(
    []
  );

  React.useEffect(() => {
    async function f() {
      const article = await API.graphql(
        // @ts-ignore
        graphqlOperation(getArticle, {
          id,
        })
      );

      let _relatedArticles = [];

      // @ts-ignore
      if (article?.data?.getArticle?.relatedArticles) {
        const relatedArticlesRes = await Promise.all(
          // @ts-ignore
          article?.data?.getArticle?.relatedArticles?.map((item: any) => {
            if (!item) return;
            return API.graphql(
              // @ts-ignore
              graphqlOperation(getArticle, {
                id: item,
              })
            );
          })
        );

        _relatedArticles = relatedArticlesRes?.map(
          (item: any) => item?.data?.getArticle
        );

        _relatedArticles = _relatedArticles.filter((item: any) => item);
      }

      // @ts-ignore
      setArticle(article.data.getArticle);
      setRelatedArticles(_relatedArticles);
    }
    f();
  }, [id]);

  return (
    <>
      {article && (
        <Article article={article} relatedArticles={relatedArticles} />
      )}
    </>
  );
}
